/**
 * Class ScriptLoader for loading webpack
 * @class ScriptsLoader
 * @namespace akioma
 */
export default class ScriptsLoader {
  /**
   * Method for loading script chunk
   * @param {string} fileName Name of file
   * @param {string} folderName Name of folder in which the file is present
   * @param {string} UiComponent Path to file if specified via UiComponent attribute on object level
   * @param {string} ext File extension default .js
   * @returns {Promise}
   */
  static async dynamicImport({
    fileName,
    ext = '.js',
    folderName = '',
    UiComponentPath = null
  }) {

    const filePath = UiComponentPath || `${folderName}${fileName}${ext}`;
    const object = await import(
      /* webpackChunkName: "[request]" */
      `../lazy/${filePath}`
    );
    return object;
  }

  /**
   * Method to retrieve folder for object type
   * @param {string} type Object type
   * @returns {string}
   */
  static getFolderNameByType(type) { // @todo will return from attribute or module
    switch (type) {
      case 'form':
        return `${type}/`;
    }
  }

  /**
   * Method for loading object type class from given name
   * @param {ak_object} obj Object of a specific type
   * @returns {Promise}
   */
  static objectTypeLoad(obj) {
    return new Promise(resolve => {
      const type = obj.objectType;
      if (isNull(window.$[`ak_${type}`])) {
        const folderName = this.getFolderNameByType(type);
        // eslint-disable-next-line no-undef
        const fileName = capitalizeFirstLetter(type);
        const UiComponentPath = obj.attributes.UiComponent;

        this.dynamicImport({
          fileName,
          folderName,
          UiComponentPath
        }).then(result => {
          akioma.log.info('New object load', result.default);
          window.$[`ak_${type}`] = result.default;
          resolve(window.$[`ak_${type}`]);
        });
      } else
        resolve(window.$[`ak_${type}`]);
    });
  }

  /**
   * Method for loading given list of object types
   * @param {array<ak_object>} objects Array of repository objects
   * @returns {Promise}
   */
  static loadObjectScriptsByType(objects) {
    const promises = objects.map(obj => this.objectTypeLoad(obj));
    return Promise.all(promises);
  }
}
